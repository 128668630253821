import {
  MapEventType,
  MarkerEventType,
  MarkerIcon,
  MarkerOptions,
  PolygonEventType,
  PolygonOptions,
  PolylineEventType,
  PolylineOptions,
} from "@inlog/inlog-maps/lib";

import circleMarkerActive from "../assets/images/circle-marker-active.png";
import circleMarker from "../assets/images/circle-marker.png";

export const AddMapEventClick = (map, event) => {
  map.addEventMap(MapEventType.Click, event);
};

export const RemoveMapEventClick = (map) => {
  map.removeEventMap(MapEventType.Click);
};

export const CreatePolyline = (map, uuid, latlng, event) => {
  const options = new PolylineOptions([latlng]);
  options.object = { uuid: uuid, type: "polyline" };
  options.color = "#009ACA";
  options.editable = true;
  options.addToMap = true;
  options.fitBounds = false;
  map.drawPolyline("polyline", options, event);
};

export const UpdatePolyline = (map, uuid, latlng) => {
  const path = map.getPolylinePath(
    "polyline",
    (object) => object.uuid === uuid
  );
  path.push(latlng);
  const options = new PolylineOptions(path);
  options.editable = true;
  options.color = "#009ACA";
  map.alterPolylineOptions(
    "polyline",
    options,
    (object) => object.uuid === uuid
  );
};

export const RenderPolyline = (map, uuid, latlng, event) => {
  const options = new PolylineOptions(latlng);
  options.object = { uuid: uuid, type: "polyline" };
  options.color = "#000000";
  options.addToMap = true;
  options.editable = true;
  options.fitBounds = false;
  map.drawPolyline("polyline", options, event);
};

export const RemovePolyline = (map, uuid) => {
  map.removePolylines("polyline", (object) => object.uuid === uuid);
};

export const RemoveAllPolylines = (map) => {
  map.removeAllPolylines();
};

export const AddEventPolyline = (map, uuid, event) => {
  map.addPolylineEvent(
    "polyline",
    PolylineEventType.DragPolyline,
    event,
    (object) => object.uuid === uuid
  );
  map.addPolylineEvent(
    "polyline",
    PolylineEventType.InsertAt,
    event,
    (object) => object.uuid === uuid
  );
  map.addPolylineEvent(
    "polyline",
    PolylineEventType.RemoveAt,
    event,
    (object) => object.uuid === uuid
  );
  map.addPolylineEvent(
    "polyline",
    PolylineEventType.SetAt,
    event,
    (object) => object.uuid === uuid
  );
};

export const alterPolylineColor = (map, uuid, color) => {
  const options = new PolylineOptions();
  options.color = color;
  map.alterPolylineOptions(
    "polyline",
    options,
    (object) => object.uuid === uuid
  );
};

export const CreatePolygon = (map, uuid, latlng, event) => {
  const options = new PolygonOptions([latlng]);
  options.object = { uuid: uuid, type: "polygon", latlng };
  options.color = "#009ACA";
  options.fillColor = "#009ACA8f";
  options.addToMap = true;
  options.editable = true;
  options.fitBounds = false;
  map.drawPolygon("polygon", options, event);
};

export const UpdatePolygon = (map, uuid, latlng) => {
  // Encontra o polígono correspondente
  const polygon = map.polygonsList.polygon.find(
    (item) => item.object.uuid === uuid
  );

  const paths = (polygon?.getPath && polygon?.getPath()) || [];

  const coordinates =
    paths?.getArray &&
    paths?.getArray()?.map((latLng) => {
      return [latLng.lat(), latLng.lng()];
    });

  // Verifica se o polígono foi encontrado e se _latlngs ou latlngs existem
  if (!polygon || (!polygon._latlngs && !coordinates?.length)) {
    console.error("Polígono não encontrado ou sem coordenadas");
    return;
  }

  // Função auxiliar para mapear latlngs
  const mapLatLngs = (latlngs) => {
    if (Array.isArray(latlngs)) {
      return latlngs.map((llm) => [llm.lat, llm.lng]);
    } else {
      return [latlngs.lat, latlngs.lng];
    }
  };

  // Mapeia as coordenadas
  const path = coordinates?.length
    ? coordinates
    : polygon._latlngs.map(mapLatLngs);

  // Adiciona a nova coordenada
  if (coordinates?.length) {
    path.push(latlng);
  } else {
    path[0].push(latlng);
  }

  // Configura as opções do polígono
  const options = new PolygonOptions(path);
  options.editable = true;
  options.color = "#009ACA";
  options.fillColor = "#009ACA8f";

  // Altera as opções do polígono
  map.alterPolygonOptions("polygon", options, (object) => object.uuid === uuid);
};

export const RenderPolygon = (map, uuid, latlng, event) => {
  const options = new PolygonOptions(latlng);
  options.object = { uuid: uuid, type: "polygon" };
  options.color = "#000000";
  options.fillColor = "#0000008f";
  options.addToMap = true;
  options.editable = true;
  options.fitBounds = false;
  map.drawPolygon("polygon", options, event);
};

export const RemovePolygon = (map, uuid) => {
  map.removePolygons("polygon", (object) => object.uuid === uuid);
};

export const RemoveAllPolygons = (map) => {
  map?.removeAllPolygons();
};

export const AddEventPolygon = (map, uuid, event) => {
  map.addPolygonEvent(
    "polygon",
    PolygonEventType.DragPolygon,
    event,
    (object) => object.uuid === uuid
  );
  map.addPolygonEvent(
    "polygon",
    PolygonEventType.InsertAt,
    event,
    (object) => object.uuid === uuid
  );
  map.addPolygonEvent(
    "polygon",
    PolygonEventType.RemoveAt,
    event,
    (object) => object.uuid === uuid
  );
  map.addPolygonEvent(
    "polygon",
    PolygonEventType.SetAt,
    event,
    (object) => object.uuid === uuid
  );
};

export const alterPolygonColor = (map, uuid, color) => {
  const options = new PolygonOptions();
  options.color = color;
  options.fillColor = `${color}8f`;
  map.alterPolygonOptions("polygon", options, (object) => object.uuid === uuid);
};

export const CreateCircleMarker = (map, uuid, latlng, event) => {
  const options = new MarkerOptions(latlng);
  options.object = {
    uuid: uuid,
    type: "circleMarker",
    _latlngs: {
      lat: latlng?.[0],
      lng: latlng?.[1],
    },
  };
  options.addToMap = true;
  options.fitBounds = false;
  options.draggable = true;
  options.icon = new MarkerIcon(circleMarkerActive, [32, 32]);
  map.drawMarker("marker", options, event);
};

export const AddEventCircleMarker = (map, uuid, event) => {
  map.addMarkerEvent(
    "marker",
    MarkerEventType.AfterDrag,
    event,
    (object) => object.uuid === uuid
  );
};

export const RenderCircleMarker = (map, uuid, latlng, event) => {
  const options = new MarkerOptions(latlng);
  options.object = { uuid: uuid, type: "circleMarker" };
  options.addToMap = true;
  options.fitBounds = false;
  options.draggable = true;
  options.icon = new MarkerIcon(circleMarker, [32, 32]);
  map.drawMarker("marker", options, event);
};

export const alterCircleMarker = (map, uuid) => {
  const options = new MarkerOptions();
  options.icon = new MarkerIcon(circleMarkerActive, [32, 32]);
  // options.icon = style
  map.alterMarkerOptions("marker", options, (object) => object.uuid === uuid);
};

export const RemoveAllCircles = (map) => {
  map.removeAllMarkers();
};

export const ResetShapeColor = (map, color) => {
  const polylineOptions = new PolylineOptions();
  polylineOptions.color = color;
  const polygonOptions = new PolygonOptions();
  polygonOptions.color = color;
  polygonOptions.fillColor = `${color}8f`;

  const circleMarkerOptions = new MarkerOptions();
  circleMarkerOptions.icon = new MarkerIcon(circleMarker, [32, 32]);

  map.alterPolygonOptions("polygon", polygonOptions);
  map.alterPolylineOptions("polyline", polylineOptions);
  map.alterMarkerOptions("marker", circleMarkerOptions);
};
